import {useState, useEffect, useContext, createContext} from 'react'
import {CognitoUserSession, CognitoUser} from 'amazon-cognito-identity-js'
import {Auth} from './aws'

// We can't use auth when generating the static parts of the website
const isBrowser = typeof window !== `undefined`

interface AuthEffect {
  isAuthenticating: boolean
  user: CognitoUser | null
  resendSignUp: (email: string) => Promise<void>
  confirmSignUp: (code: string, email: string) => Promise<void>
  signUp: (email: string, password: string) => Promise<void>
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  getCurrentUser: () => Promise<CognitoUser | undefined>
  setReturnTo: (path: string) => void
  getReturnTo: () => string | null
}

// Create a context that we can provide and use
export const authContext = createContext<AuthEffect | undefined>(undefined)

export const useAuthEffect = (): AuthEffect | undefined => {
  const [isAuthenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState(null as CognitoUser | null)

  useEffect(() => {
    if (!isBrowser) return
    try {
      // Check for a current valid session in the session storage
      Auth.currentSession()
        .then(async _cognitoUserSession => {
          const cognitoUser = await Auth.currentAuthenticatedUser()
          setUser(cognitoUser)
          setAuthenticating(false)
        })
        .catch(error => {
          // "No current user" is expected when auth is missing or expired
          if (error !== 'No current user') {
            console.error({error})
          }
          setUser(null)
          setAuthenticating(false)
        })
    } catch (error) {
      console.error({error})
    }
  }, [])

  const signUp = async (email: string, password: string): Promise<void> => {
    if (!isBrowser) return
    setUser(null)
    email = email.toLowerCase()
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
      },
    })
  }

  const resendSignUp = async (email: string): Promise<void> => {
    if (!isBrowser) return
    email = email.toLowerCase()
    await Auth.resendSignUp(email)
  }

  const confirmSignUp = async (code: string, email: string): Promise<void> => {
    if (!isBrowser) return
    setUser(null)
    await Auth.confirmSignUp(email, code)
  }

  const signIn = async (email: string, password: string): Promise<void> => {
    if (!isBrowser) return
    setUser(null)
    email = email.toLowerCase()
    const cognitoUser = await Auth.signIn(email, password)
    setUser(cognitoUser)
  }

  const signOut = async (): Promise<void> => {
    if (!isBrowser) return
    setUser(null)
    await Auth.signOut()
  }

  const getCurrentUser = async (): Promise<CognitoUser | undefined> => {
    if (!isBrowser) return
    return await Auth.currentAuthenticatedUser()
  }

  const setReturnTo = (path: string): void => {
    if (!isBrowser) return
    // We don't want to store login redirects in session storage because nobody wants to return there
    if (path && path.match(/^\/(logout|login|signup|forgot)/)) {
      return
    }
    if (path) {
      sessionStorage.setItem('return-to', path)
    } else {
      sessionStorage.removeItem('return-to')
    }
  }

  const getReturnTo = (): string | null => {
    if (!isBrowser) return null
    const path = sessionStorage.getItem('return-to')
    if (path) {
      sessionStorage.removeItem('return-to')
    }
    // We don't want to store login redirects in session storage because nobody wants to return there
    if (path && path.match(/^\/(logout|login|signup|forgot)/)) {
      return null
    }
    return path
  }

  return {
    isAuthenticating,
    user,
    signUp,
    resendSignUp,
    confirmSignUp,
    signIn,
    signOut,
    getCurrentUser,
    setReturnTo,
    getReturnTo,
  }
}

export const useAuth = (): AuthEffect | undefined => {
  return useContext(authContext)
}
