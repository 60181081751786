import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'

const isBrowser = typeof window !== `undefined`

const apiUrl = process.env.API_URL
const region = process.env.AWS_REGION
const identityPoolId = process.env.IDENTITY_POOL_ID
const userPoolId = process.env.USER_POOL_ID
const userPoolWebClientId = process.env.USER_POOL_WEB_CLIENT_ID

// cookieStorage: {
//   //domain: '.kindawow.com',
//   domain: 'localhost',
//   path: '/',
//   expires: 365,
//   secure: true
// },

if (isBrowser) {
  try {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region,
        identityPoolId,
        userPoolId,
        userPoolWebClientId,
      },
      API: {
        endpoints: [
          {
            name: 'api',
            endpoint: apiUrl,
            region,
          },
        ],
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export {API, Auth}
