import React from 'react'
import PropTypes from 'prop-types'
import {useAuthEffect, authContext} from './use-auth'

interface Props {
  readonly children: React.ReactNode
}

const ProvideAuth: React.FC<Props> = ({children}) => {
  const auth = useAuthEffect()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

ProvideAuth.propTypes = {
  children: PropTypes.node,
}

export default ProvideAuth
